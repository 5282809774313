<template>
    <div id="app">
      <TheHead />
      <transition name="fade" mode="out-in">
      <div key=1 v-if="website == 1"> 
        <div class="headline text-mainbluebutton text-4xl pt-10 pb-2 font-normal">
  

        Ist Ihr Unternehmen abgesichert?
      </div>
      <div class="text-base pb-10 font-medium px-8">
        Schließen Sie den SafeScore ab um zu checken, wie gut Ihr Unternehmen abgesichert ist.
      </div>
<div class="flex justify-center">
<div class="fullselect flex mx-8 md:mx-0">
  <label for="all" class="cursor-pointer border-2 md:rounded-full rounded-5xl p-4 border-mainblue flex justify-between space-x-4" :class="{ 'isblue': userIds.length == Object.keys(questions).length }">
    <div class="checkbox flex items-center">
        <input id="all" type="checkbox" @click="selectAll" v-model="allSelected"><label for="all"></label>
    </div>
    <div class="checkbox flex items-center text-sm font-bold" :class="{ 'isbluetext': userIds.length == Object.keys(questions).length }">
        VOLLSTÄNDIGER CHECK
    </div>
    <div class="checkbox text-sm items-center text-left" :class="{ 'isbluetext': userIds.length == Object.keys(questions).length }">
        Der vollständige Check kann bis zu 2 Minuten dauern, dafür bietet er eine <br class="md:block hidden" />umfangreiche Analyse. Der SafeScore kann somit besser dargestellt werden.
    </div>
  </label>
</div>
</div>
<div class="font-medium pb-6 pt-8">
  oder wählen Sie eine Kategorie
</div>
<div class="w-full flex justify-center items-center flex-wrap p-4 md:p-0">
                <div v-for="q in questions" :key="q.id" class="flex space-x-2 md:m-4 items-center justify-center w-1/2 md:w-auto">
                    <div class="p-1 md:p-0">
                    <label :for="q.id" class="cursor-pointer border-2 rounded-5xl p-4 border-mainblue w-full md:w-64 md:h-64 flex flex-wrap" :class="{ 'isblue': userIds.includes(q.id)}">
                    <div class="w-full">
                        <input :id="q.id" type="checkbox" v-model="userIds" @change="select" :value="q.id" class="flex justify-end"><label :for="q.id"></label>
                    </div>
                    <div class="w-full flex justify-center">
                      <div class="z-30 h-24 w-24 bg-black" :class="{ 'isblue2': userIds.includes(q.id)}" :style="{ 'mask': 'url(' + getImgUrl(q.logo) +') no-repeat center / contain', '-webkit-mask': 'url(' + getImgUrl(q.logo) +') no-repeat center / contain' }"></div>
                    
                    </div>
                    <div class="w-full flex items-center justify-center mb-4 font-medium" :class="{ 'isbluetext': userIds.includes(q.id)}">{{ q.name }}</div>
                    <!--<div class="progress1">
                      <div class="bar h-64 w-64 block" :style="'background: #fff; background: linear-gradient(0deg, #f00 ' + progress1() + '%, #fff ' + progress1() + '%);'">x</div>
                    </div>-->
                    </label>
                    </div>
                </div>
</div>

<div v-if="disabled">
<button :disabled="disabled" @click="website = 2" class="focus:outline-none border-solid font-medium text-mainblue border-2 rounded-full p-4 border-mainblue justify-center items-center inline-block cursor-pointer px-5">
  Auswahl treffen!
</button>
</div>
<div v-if="!disabled">
  <button :disabled="disabled" @click="website = 2" class="focus:outline-none border-solid font-medium bg-mainblue border-2 text-white rounded-full p-4 border-mainblue justify-center items-center inline-block cursor-pointer px-5">
  Ich bin bereit, los gehts!
</button>
</div>
        <!--<span>Selected Ids: {{ userIds }}</span>--> 

</div>



<div key=2 v-if="website == 2">
          <div class="headline text-mainbluebutton text-4xl pt-10 pb-2 font-normal">
        Und los gehts...
      </div>
      <div class="text-base pb-10 font-medium">
        Beantworten Sie Schritt für Schritt die Fragen
      </div>
<div class="hidden md:flex items-center justify-center space-x-8">
  <template v-for="q in questions">
    <template v-if="userIds.includes(q.id)">
      <div :key="'x' + q.id" class="flex flex-wrap justify-center">
        <div class="w-full flex justify-center">
        <transition>
        <img :key="'img' + q.id" :src="getImgUrl(q.logo)" class="text-red-500 stroke-current transition" :class="{ 'bigger': q.id == page }" />
        </transition>
        </div>
        <div class="w-full flex justify-center my-4 font-medium">
        {{ q.name }}
        </div>
        <div class="mt-4 flex justify-center">
        <steps :steps="q.content.length" :step="step" :question="q.id" :page="page"></steps>
        </div>
      </div>
    </template>
  </template>
</div>


<div class="flex items-center justify-center space-x-8">
  <template v-for="q in questions">
    <template v-if="userIds.includes(q.id)">
      <template v-if="q.id == page">
        <template v-for="c in q.content" class="flex flex-wrap">
          <template v-if="c.id == question">
            <div :key="'o' + c.id">
              
              <div class="font-medium mt-10 mb-4 text-sm">{{ q.name }} — Frage {{ c.id }}/{{ q.content.length }}</div>
              <transition name="slide-fade" mode="out-in">
                <div class="flex justify-center w-full">
              <div :key="c.id" class="px-4 md:px-64 transition font-medium text-2xl w-full"> {{ c.frage }} </div>
              </div>
              </transition>
              <div class="flex w-full justify-center">
                <div class="mt-8 flex flex-wrap">
                  <div @click="sitechange(c.id, q.content.length, true, c.punkte, q.shortname, c.frage, 1)" class="focus:outline-none transition hover:bg-mainblue hover:text-white mr-2 flex-1 bg-white border-2 text-mainblue rounded-full py-2 px-4 border-mainblue justify-center items-center inline-block cursor-pointer">Ja</div>
                  <div @click="sitechange(c.id, q.content.length, false, c.punkte, q.shortname, c.frage, 2)" class="focus:outline-none transition hover:bg-mainblue hover:text-white mr-2 flex-1 bg-white border-2 text-mainblue rounded-full py-2 px-4 border-mainblue justify-center items-center inline-block cursor-pointer">Nein</div>
                  <div @click="sitechange(c.id, q.content.length, false, c.punkte, q.shortname, c.frage, 3)" class="focus:outline-none transition hover:bg-mainblue hover:text-white mr-2 flex-1 bg-white border-2 text-mainblue rounded-full py-2 px-4 border-mainblue justify-center items-center inline-block cursor-pointer whitespace-nowrap">Unklar</div>
                  <div @click="startover()" class="focus:outline-none hover:bg-white hover:text-mainblue transition mr-2 flex-1 bg-mainblue border-2 border-mainblue text-white rounded-full py-2 px-4 border-mainblue justify-center items-center inline-block cursor-pointer">Neustart</div>
                </div>        
              </div>
            </div>
          </template>
        </template>
      </template>
    </template>
  </template>
</div>
</div>

<div key=2 v-if="website == 3" class="overflow-hidden">
  <div class="headline text-mainbluebutton text-4xl pt-10 pb-10 font-normal">
        ...das wars schon. Ihr SafeScore beträgt:
      </div>
  <template v-for="q in questions">
    <template v-if="userIds.includes(q.id)">
      <template v-if="q.id == page">
        <template v-for="c in q.content" class="flex flex-wrap">
          <template v-if="c.id == question">
            <div :key="'o' + c.id" class="overflow-hidden">
              <!--<div class="pyro overflow-hidden">
  <div class="before overflow-hidden"></div>
  <div class="after overflow-hidden"></div>
</div>-->

         <span class="flex h-32 w-32 justify-center w-full ">
  <span class="animate-ping1 absolute inline-flex h-32 w-32 rounded-full opacity-75" :class="{ 'bg-red-400': (punktehaben / allepunkte)*100 <= 49, 'bg-orange-300': (punktehaben / allepunkte)*100 > 50 && (punktehaben / allepunkte)*100 <= 79, 'bg-green-400': (punktehaben / allepunkte)*100 > 80 }"></span>
  <span class="relative inline-flex rounded-full h-32 w-32 justify-center items-center text-white text-4xl" :class="{ 'bg-red-400': (punktehaben / allepunkte)*100 <= 49, 'bg-orange-300': (punktehaben / allepunkte)*100 > 50 && (punktehaben / allepunkte)*100 <= 79, 'bg-green-400': (punktehaben / allepunkte)*100 > 80 }">{{ Math.round((punktehaben / allepunkte) * 100,0) }}%</span>
</span>
<span class="flex justify-center w-full justify-center items-center space-x-2 mt-6">
  <div class="">
<span class="inline-flex rounded-full h-2 w-2 justify-center items-center bg-green-400 text-4xl"></span> <span class="text-green-400">abgesichert</span>
</div>
 <div class="">
<span class="ml-4 inline-flex rounded-full h-2 w-2 justify-center items-center bg-orange-400 text-4xl"></span> <span class="text-orange-400">kritisch</span>
</div>
 <div class="">
<span class="ml-4 inline-flex rounded-full h-2 w-2 justify-center items-center bg-red-400 text-4xl"></span> <span class="text-red-400">sofort handeln</span>
</div>
</span>
<div v-if="!messagesent">
<div class="text-xl font-medium p-8">
  Sie möchten eine detaillierte Auswertung erhalten?<br />Füllen Sie das Formular aus und wir senden diese per Mail zu.
</div>
<div class="w-full flex justify-center pb-8">
<div class="flex w-full md:w-1/3 flex-wrap justify-around mx-2 md:mx-0">
  <div class="flex w-1/2 p-2"><input type="text" class="w-full bg-mainblueform rounded-full px-4 py-2 focus:outline-none" placeholder="Ansprechpartner" /></div>
  <div class="flex w-1/2 p-2"><input type="text" class="w-full bg-mainblueform rounded-full px-4 py-2 focus:outline-none" placeholder="Firma" /></div>
  <div class="flex w-1/2 p-2"><input type="text" class="w-full bg-mainblueform rounded-full px-4 py-2 focus:outline-none" placeholder="Telefon" /></div>
  <div class="flex w-1/2 p-2"><input v-model="email" type="text" class="w-full bg-mainblueform rounded-full px-4 py-2 focus:outline-none" placeholder="E-Mail *" /></div>
</div>
</div>
<div class="flex justify-center mb-8">
<div class="fullselect flex mx-4 md:m-0">
  <label for="allx" class="cursor-pointer border-2 rounded-full p-4 border-mainblue flex justify-between space-x-4 " :class="{ 'isblue': agb }">
    <div class="checkbox flex items-center">
        <input id="allx" type="checkbox" v-model="agb"><label for="allx"></label>
    </div>
    <div class="checkbox flex items-center text-sm font-bold">
        Ich bin einverstanden
    </div>
    <div class="flex checkbox text-sm items-center text-left" >
        Hiermit bestätige ich die AGB und den Datenschutz
    </div>
  </label>
</div>
</div>
{{ getAuswertung() }}
                  <div class="focus:outline-none transition hover:bg-mainblue hover:text-white mr-2 flex-1 bg-white border-2 text-mainblue font-medium rounded-full py-2 px-4 border-mainblue justify-center items-center inline-block cursor-pointer"  @click="sendMail()">SafeScore erhalten</div>
<div class="disclaimer font-sm text-mainblue mt-16">
  * Die mit Sternchen markierten Felder sind notwendig um eine detallierte Auswertung zu erhalten.
  </div>
  </div>
    <div v-if="messagesent">
      <div class="text-xl font-medium p-8">
  Vielen Dank. Ihre Auswertung sollte in Kürze in Ihrer Mailbox eintreffen.
</div>
      </div>
              </div>
          </template>
        </template>
      </template>
    </template>
  </template>
</div>
</transition>

<TheFoot />

  

  </div>
</template>

<script>
import Steps from './components/Steps.vue';
import TheHead from './components/TheHead.vue';
import TheFoot from './components/TheFoot.vue';

export default {
  name: 'App',
  components: {
    Steps,
    TheHead,
    TheFoot,

  },
  data () {
    return {
      messagesent: false,
      isActive: false,
      email: '',
      showModalx: true,
      totalSteps: 100,
      website: 1,
      stepsDone: 40,
      step: 1,
      page: 1,
      ansprechpartner: '',
      firma: '',
      telefon: '',
      agb: false,
      question: 1,
      currentTab: 0,
      auswertung: {},
      actual: [],
      users: [ 
            { "id": "mechanischesicherung", "name": "Mechanische Sicherung" }, 
            { "id": "elektronischesicherung", "name": "Elektronische Sicherung" },
            { "id": "itdatensicherung", "name": "Informationssicherheit" }, 
            { "id": "versicherungen", "name": "Versicherungen" }
        ],
        selected: [],
        questions: {
          "mechanischesicherung": {
            "id": 1,
            "name": "Mechanische Sicherung",
            "shortname": "mechanischesicherung",
            "logo": "mechanischesicherung.svg",
            "score": [
              { "pointsfrom": 0, "pointsto": 49, "text": "Eine mechanische Außenhautsicherung Ihrer Immobilie ist nicht gewährleistet. Ihre Immobilie bietet keinen ausreichenden Schutz vor Einbruchsversuchen." },
              { "pointsfrom": 50, "pointsto": 79, "text": "Die mechanische Außenhautsicherung Ihrer Immobilie ist vorhanden, aber ausbaufähig." },
              { "pointsfrom": 80, "pointsto": 100, "text": "Ihr SafeScore hat ergeben, dass die mechanische Außenhautsicherung Ihrer Immobilie sichergestellt ist." }
            ],
            "content": [
            { "id": 1, "frage": "Ist das Türblatt Ihrer Eingangstür mindestens 5cm stark?", "punkte": 3/3 },
            { "id": 2, "frage": "Liegen die Türbänder / Scharniere innen?", "punkte": 1/3 },
            { "id": 3, "frage": "Hat die Tür eine Mehrfachverriegelung?", "punkte": 3/3 },
            { "id": 4, "frage": "Wurde das Schließblech mindestens zweimal fest im Mauerwerk verankert?", "punkte": 2/3 },
            { "id": 5, "frage": "Hat jede Außentür Profilzylinderschlösser?", "punkte": 3/3 },
            { "id": 6, "frage": "Sind die Eingangstüren durch ein Zusatzschloss gesichert und von außen verriegelbar?", "punkte": 2/3 },
            { "id": 7, "frage": "Sitzt der Türzylinder bündig im Beschlag?", "punkte": 2/3 },
            { "id": 8, "frage": "Sind an den Fenstern – vor allem im Erdgeschoss – abschließbare einbruchhemmende Beschläge angebracht?", "punkte": 3/3 },
            { "id": 9, "frage": "Sind die Fenster mit zusätzlichen Fenster Schlössern gesichert?", "punkte": 3/3 },
            { "id": 10, "frage": "Sind leicht zugängliche Fenster in oberen Stockwerken ebenso mit solchen Beschlägen versehen?", "punkte": 1/3 },
            { "id": 11, "frage": "Sind in den Fenstern / Terrassentüren im Erdgeschoss abschließbare Rollladen verbaut?", "punkte": 3/3 },
            { "id": 12, "frage": "Gibt es Vorrichtungen, die das Hochschieben der Rollladen verhindern?", "punkte": 1/3 },
            { "id": 13, "frage": "Sind die Kellerfenster mit nicht abschraubbaren Gittern versehen?", "punkte": 1/3 }
          ]},
          "elektronischesicherung": {
            "id": 2,
            "name": "Elektronische Sicherung",
            "shortname": "elektronischesicherung",
            "logo": "elektronischesicherung.svg",
            "score": [
              { "pointsfrom": 0, "pointsto": 49, "text": "Eine elektronische Außenhautsicherung Ihrer Immobilie ist nicht gewährleistet. Ihre Immobilie bietet keinen ausreichenden Schutz vor Einbruchsversuchen." },
              { "pointsfrom": 50, "pointsto": 79, "text": "Die elektronische Außenhautsicherung Ihrer Immobilie ist vorhanden, aber ausbaufähig." },
              { "pointsfrom": 80, "pointsto": 100, "text": "Ihr SafeScore hat ergeben, dass die elektronische Außenhautsicherung Ihrer Immobilie sichergestellt ist." }
            ],
            "content": [
            { "id": 1, "frage": "Sind Kamerasysteme verbaut?", "punkte": 3/3 },
            { "id": 2, "frage": "Sind elektronische Zutrittskontrollen verbaut?", "punkte": 3/3 },
            { "id": 3, "frage": "Ist eine Alarmanlage verbaut?", "punkte": 3/3 },
            { "id": 4, "frage": "Wurde die Alarmanlage von einem Fachbetrieb installiert? Ist sie vom Verband der Schadensversicherer anerkannt?", "punkte": 2/3 },
            { "id": 5, "frage": "Ist der Signalgeber für Einbrecher schwer zu erreichen?", "punkte": 1/3 },
            { "id": 6, "frage": "Wird die Anlage regelmäßig von einem Fachbetrieb gewartet?", "punkte": 2/3 },
            { "id": 7, "frage": "Schaltet sich Ihre Alarmanlage an eine ständig besetzte Stelle, zum Beispiel einem Sicherheitsdienst, weiter?", "punkte": 2/3 }
          ]},
          "itdatensicherung": {
            "id": 3,
            "name": "Informationssicherheit",
            "shortname": "itdatensicherung",
            "logo": "itdatensicherung.svg",
            "score": [
              { "pointsfrom": 0, "pointsto": 49, "text": "Maßnahmen zur Informationssicherheit innerhalb Ihres Unternehmens sind nicht vorhanden. Ihr Unternehmen bietet keinen ausreichenden Schutz vor Übergriffen." },
              { "pointsfrom": 50, "pointsto": 79, "text": "Maßnahmen zur Informationssicherheit innerhalb Ihres Unternehmens sind implementiert, aber ausbaufähig." },
              { "pointsfrom": 80, "pointsto": 100, "text": "Ihr SafeScore hat ergeben, dass Maßnahmen zur Informationssicherheit innerhalb Ihres Unternehmens implementiert und etabliert sind." }
            ],
            "content": [
            { "id": 1, "frage": "Können Login-Informationen zentral und in Echtzeit gesammelt und Sicherheitsvorfälle automatisiert und effektiv behandelt werden?", "punkte": 2/3 },
            { "id": 2, "frage": "Wird Ihre E-Mail-Kommunikation geschützt?", "punkte": 3/3 },
            { "id": 3, "frage": "Sind Prozesse und Lösungen zur Wiederherstellung unternehmenskritischer Systeme und Daten etabliert", "punkte": 3/3 },
            { "id": 4, "frage": "Sind alle im Unternehmen genutzten Software-Produkte bekannt und ist die Datensicherheit gewährleistet?", "punkte": 2/3 },
            { "id": 5, "frage": "Sind Schutzmaßnahmen wie Netzwerk Segmentierung etabliert?", "punkte": 3/3 },
            { "id": 6, "frage": "Sind die Kommunikationsverbindungen zwischen IT / Anwender / Kunde / Partner detailliert bekannt und durchgängig geschützt?", "punkte": 1/3 },
            { "id": 7, "frage": "Werden regelmäßig Schwachstellen-Scans durchgeführt und verwendete Firmware überprüft?", "punkte": 1/3 },
            { "id": 8, "frage": "Ist ein unternehmensweiter Malware-Schutz implementiert, der auf allen Ebenen selbstständig Angriffe erkennt und Maßnahmen einleitet?", "punkte": 2/3 },
            { "id": 9, "frage": "Besteht nach Artikel 37 DSGVO die Pflicht zur Ernennung eines Datenschutzbeauftragten?", "punkte": 3/3 },
            { "id": 10, "frage": "Gibt es in Ihrem Unternehmen bereits einen Datenschutzbeauftragten?", "punkte": 1/3 },
           { "id": 11, "frage": "Führen Sie ein Verzeichnis aller Datenverarbeitungstätigkeiten, das Verantwortlichkeiten, Verarbeitungszwecke und Löschfristen listet?", "punkte": 3/3 },
           { "id": 12, "frage": "Beinhaltet Ihre Webseite eine Datenschutzerklärung?", "punkte": 3/3 },
           { "id": 13, "frage": "Wurden Mitarbeiter, die mit personenbezogenen Daten arbeiten, über die Datenvereinbarung informiert und schriftlich zur Geheimhaltung der unternehmensbezogenen Daten verpflichtet?", "punkte": 2/3 },
           { "id": 14, "frage": "Sind Sie und Ihre Mitarbeiter für den korrekten Umgang mit personenbezogenen Daten geschult?", "punkte": 2/3 },
           { "id": 15, "frage": "Nutzen Sie in Ihrem Unternehmen bereits eine Datenschutz-Software?", "punkte": 1/3 }
          ]},
          "versicherungen": {
            "id": 4,
            "name": "Versicherungen",
            "shortname": "versicherungen",
            "logo": "versicherungen.svg",
            "score": [
              { "pointsfrom": 0, "pointsto": 49, "text": "Ein ausreichender Versicherungsschutz Ihres Unternehmens ist nicht gewährleistet." },
              { "pointsfrom": 50, "pointsto": 79, "text": "Der Versicherungsschutz Ihres Unternehmens ist gewährleistet, aber ausbaufähig." },
              { "pointsfrom": 80, "pointsto": 100, "text": "Ihr SafeScore hat ergeben, dass der Versicherungsschutz Ihres Unternehmens sichergestellt ist." }
            ],
            "content": [
            { "id": 1, "frage": "Besteht eine Ertragsausfallversicherung für Ihr Unternehmen, die Sie gegen entgangene Betriebsgewinne und fortlaufende Kosten absichert?", "punkte": 2/3 },
            { "id": 2, "frage": "Verfügt Ihr Unternehmen über eine Geschäftsinhaltsversicherung, die Sie gegen Schaden an Inventar, Produkten und Warenbeständen absichert?", "punkte": 3/3 },
            { "id": 3, "frage": "Haben Sie eine Elektronikversicherung für Ihr Unternehmen abgeschlossen, die Sie gegen Schaden an elektronischen Anlagen und Geräten absichert?", "punkte": 2/3 }
          ]},
          
          

        },
        allSelected: false,
        userIds: [],
        punktehaben: 0,
        allepunkte: 600,
        msg: ''
    }
  },
  mounted() {
    
  },
  watch: {
    userIds: {
      /* eslint-disable no-unused-vars */
      handler(val, oldVal) {
        var points = 0
        //console.log(this.userIds)
        var allqs = Object.values(this.questions)
        
        val.forEach(function(id) {
          //console.log(allqs[id])
          allqs[id-1].content.forEach(function(qid) { //id = id - 1
          //console.log(qid)
          points = points + qid.punkte
          })
        })
        this.allepunkte = points
        //console.log(points)
    /* eslint-enable no-unused-vars */
        //if (this.userIds.length == Object.keys(this.questions).length) {
         
         //for (val in this.questions) {
  
           //alert(this.questions[val].punkte)
          //this.allepunkte = this.allepunkte + this.questions[val].punkte
          //}
      },
      deep: true
    }
  },
  computed: {
     
  disabled: ({ userIds }) => userIds.length === 0
},
  methods: {
    sendMail() {
      if(this.email == '') {
        alert('Bitte eine E-Mail Adresse eintragen.')
        return false
      }
      if(this.agb == false) {
        alert('Bitte die AGB akzeptieren.')
        return false
      }
      var currentdate = new Date(); 
      var datum = ('0' + currentdate.getDate()).slice(-2) + "."
                + ('0' + (currentdate.getMonth()+1)).slice(-2)  + "." 
                + currentdate.getFullYear() + " um "  
                + ('0' + currentdate.getHours()).slice(-2) + ":"
                + ('0' + currentdate.getMinutes()).slice(-2);
      var alltheprozent = Math.round((this.punktehaben / this.allepunkte) * 100,0)
      var color = ""
      if(alltheprozent >= 0 && alltheprozent <= 49) {
          color = "#fc8181"
        }
        if(alltheprozent >= 50 && alltheprozent <= 79) {
          color = "#f6ad55"
        }
        if(alltheprozent >= 80 && alltheprozent <= 100) {
          color = "#68d391"
        }
    this.axios.post('https://www.neue-heckinghauser-apotheke.de:8443/api/mail/sendsafescore', {
      email: this.email,
      auswertung: this.auswertung,
      allprozent: alltheprozent,
      color: color,
      datum: datum,
      ansprechpartner: this.ansprechpartner,
      firma: this.firma,
      telefon: this.telefon
    },
           {
              headers: {
                "Access-Control-Allow-Origin": "*",
                'Content-Type': 'application/json'
              }
           })
           .then(response => {
             console.log('ok')
             this.msg = response
            this.messagesent = true
           })
           .catch(e => {
             console.log(e)
             this.errors.push(e)
           })
    },

   ergebnis: function() {
     console.log(this.questions)
      /* var go = 0
       Object.entries(this.questions)
       for (let i = 0; i < this.questions.length; i++) {
         console.log("x")

         go = go + JSON.parse(JSON.stringify(this.questions[i].punkte))
         console.log(this.questions[i].punkte)
            }
       return go

      this.questions.forEach(function(val) {
          this.allepunkte = this.allepunkte + this.questions[val].punkte
      }) 
      console.log(this.allepunkte)
      return this.allepunkte*/
    },
    getAuswertung: function() {
      Object.keys(this.auswertung).forEach(key => {
        //keys
        var allpoints = 0
        var points = 0
        
        this.auswertung[key].fragen.forEach(x => {
          allpoints = allpoints + x.punkte
          if (x.antwort == 1) {
            points = points + x.punkte
          }
        })

        var prozent = (points/allpoints)*100
        var answer = ""
        var name = this.questions[key].name
        var color = ""

        if(prozent >= 0 && prozent <= 49) {
          color = "#fc8181"
        }
        if(prozent >= 50 && prozent <= 79) {
          color = "#f6ad55"
        }
        if(prozent >= 80 && prozent <= 100) {
          color = "#68d391"
        }


        this.questions[key].score.forEach(y => {
          if (prozent >= y.pointsfrom && prozent <= y.pointsto ) {
            answer = y.text
          }
        })

        this.auswertung[key].data = { allpoints: allpoints, points: points, prozent: Math.round(prozent,0), answer: answer, name: name, color: color}
        //console.log(this.auswertung)
      });
      //console.log(this.auswertung)
    return null
      
    },
    sitechange: function(q, maxq, status, p, thema, frage, response) {
      //this.auswertung.push(thema)
      if (thema in this.auswertung) {
        this.auswertung[thema].fragen.push({ frage: frage, antwort: response, punkte: p })
      } else {
        // new object with new key, pushing array
        this.auswertung[thema] = {}
        this.auswertung[thema].fragen = []
        this.auswertung[thema].fragen.push({ frage: frage, antwort: response, punkte: p })
      }
    
      //console.log(this.auswertung)
       if (status) {
            this.punktehaben = this.punktehaben + p
        }

      if (q == maxq) {
  
        //filter for next higher value of page
        const result = this.userIds.filter( x => x > this.page )
        
        if(this.page == Math.max.apply(Math, this.userIds)) {
          this.website = 3
          return
        }
        //alert(result + " - " + Math.min.apply(Math, result))
        this.page = Math.min.apply(Math, result)
        //this.page = Math.min.apply(Math, this.userIds)
        //this.page++
        this.step = 1
        this.question = 1
      } else {
        this.step++
        this.question++
      }
    },
    startover: function() {
      this.page = 1
      this.step = 1
      this.website = 1
      this.question = 1
      this.userIds = []
      this.punktehaben = 0
      this.allepunkte = 0
      this.auswertung = {}
    },
    progress1: function() {
        return this.stepsDone / this.totalSteps * 100
      },
    getImgUrl: function(pic) {
    return require('./assets/img/' + pic);
  },
        selectAll: function(user) {
            this.userIds = [];

            if (!this.allSelected) {
                for (user in this.questions) {
                    this.userIds.push(this.questions[user].id);
                }
            }
        },
        select: function() {
            if (this.userIds.length == Object.keys(this.questions).length) {
              this.allSelected = true;
              this.page = Math.min.apply(Math, this.userIds);
              this.question = 1;
            } else {
              this.allSelected = false;
              this.page = Math.min.apply(Math, this.userIds);
              this.question = 1;
            }
        },
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.isblue {
    background-color: rgba(83, 135, 198, .1) !important;
    border-color: #5387C6 !important;
}
.isblue2 {
  background-color: #5387C6 !important;
}
.isbluetext {
  color: #5387C6 !important;
}
input[type="checkbox"] {
  display:none;
}
input[type="checkbox"] + label::before {
  width: 25px;
  height: 25px;
  border-radius: 10px;
  border: 2px solid rgba(83, 135, 198, 1);
  background-color: #fff;
  display: block;
  content: "";
  float: right;
  margin-right: 5px;
}
input[type="checkbox"]:checked+label::before {
  box-shadow: inset 0px 0px 0px 6px #5387C6;
  background-color: #fff;
  border-color: #5387C6;
}


.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}



/* Hide all steps by default: */
.tab {
  display: none;
}

button {
  background-color: #4CAF50;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}


.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(-10px);
  opacity: 0;
}
.transition {
  -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.bigger {
  transform: scale(1.2);
}
.smallagain {
  transform: scale(1) !important;
}


















.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: 199px -145.6666666667px #002bff, -181px -126.6666666667px #ff009d, -128px -137.6666666667px #ff4000, 16px -376.6666666667px #ea00ff, -180px 70.3333333333px #00ffaa, 95px 30.3333333333px #a200ff, 212px -407.6666666667px #ffe600, 18px 39.3333333333px #0009ff, -245px -260.6666666667px #00a2ff, 88px -26.6666666667px #ff00fb, -68px -221.6666666667px #2200ff, -104px -18.6666666667px #00ffa6, 175px -299.6666666667px #ff6200, -5px -396.6666666667px #ffbf00, 5px -104.6666666667px #00ff22, 119px -364.6666666667px #37ff00, -42px -195.6666666667px #8400ff, -115px -99.6666666667px #0091ff, 35px -356.6666666667px #ff0033, 212px -9.6666666667px #ae00ff, -3px 28.3333333333px #00ffcc, -56px -365.6666666667px #ccff00, 129px -409.6666666667px #0095ff, 112px -242.6666666667px #aaff00, -89px 4.3333333333px #1aff00, 202px -41.6666666667px #a600ff, 144px -404.6666666667px #0400ff, -189px 17.3333333333px #00b7ff, 180px -35.6666666667px #00ffaa, -97px -174.6666666667px #00ffea, 243px -221.6666666667px #1aff00, -72px -26.6666666667px #ff0022, -119px -301.6666666667px #ff001a, -134px -179.6666666667px #ff6600, 48px -251.6666666667px #d500ff, 79px -144.6666666667px #ff006a, 19px -254.6666666667px #00ff6f, -132px -384.6666666667px #00ff22, -198px -9.6666666667px #ff4d00, -180px -31.6666666667px #0037ff, 164px 78.3333333333px #e1ff00, 189px -289.6666666667px #0026ff, -178px -92.6666666667px #7b00ff, 109px -233.6666666667px #ff0051, -219px -356.6666666667px #0015ff, -16px -53.6666666667px #6a00ff, 30px -194.6666666667px #c4ff00, -153px -190.6666666667px #0037ff, -198px -221.6666666667px #c400ff, -50px -234.6666666667px #ff00f2, -123px -297.6666666667px #95ff00;
  }
}
@-moz-keyframes bang {
  to {
    box-shadow: 199px -145.6666666667px #002bff, -181px -126.6666666667px #ff009d, -128px -137.6666666667px #ff4000, 16px -376.6666666667px #ea00ff, -180px 70.3333333333px #00ffaa, 95px 30.3333333333px #a200ff, 212px -407.6666666667px #ffe600, 18px 39.3333333333px #0009ff, -245px -260.6666666667px #00a2ff, 88px -26.6666666667px #ff00fb, -68px -221.6666666667px #2200ff, -104px -18.6666666667px #00ffa6, 175px -299.6666666667px #ff6200, -5px -396.6666666667px #ffbf00, 5px -104.6666666667px #00ff22, 119px -364.6666666667px #37ff00, -42px -195.6666666667px #8400ff, -115px -99.6666666667px #0091ff, 35px -356.6666666667px #ff0033, 212px -9.6666666667px #ae00ff, -3px 28.3333333333px #00ffcc, -56px -365.6666666667px #ccff00, 129px -409.6666666667px #0095ff, 112px -242.6666666667px #aaff00, -89px 4.3333333333px #1aff00, 202px -41.6666666667px #a600ff, 144px -404.6666666667px #0400ff, -189px 17.3333333333px #00b7ff, 180px -35.6666666667px #00ffaa, -97px -174.6666666667px #00ffea, 243px -221.6666666667px #1aff00, -72px -26.6666666667px #ff0022, -119px -301.6666666667px #ff001a, -134px -179.6666666667px #ff6600, 48px -251.6666666667px #d500ff, 79px -144.6666666667px #ff006a, 19px -254.6666666667px #00ff6f, -132px -384.6666666667px #00ff22, -198px -9.6666666667px #ff4d00, -180px -31.6666666667px #0037ff, 164px 78.3333333333px #e1ff00, 189px -289.6666666667px #0026ff, -178px -92.6666666667px #7b00ff, 109px -233.6666666667px #ff0051, -219px -356.6666666667px #0015ff, -16px -53.6666666667px #6a00ff, 30px -194.6666666667px #c4ff00, -153px -190.6666666667px #0037ff, -198px -221.6666666667px #c400ff, -50px -234.6666666667px #ff00f2, -123px -297.6666666667px #95ff00;
  }
}
@-o-keyframes bang {
  to {
    box-shadow: 199px -145.6666666667px #002bff, -181px -126.6666666667px #ff009d, -128px -137.6666666667px #ff4000, 16px -376.6666666667px #ea00ff, -180px 70.3333333333px #00ffaa, 95px 30.3333333333px #a200ff, 212px -407.6666666667px #ffe600, 18px 39.3333333333px #0009ff, -245px -260.6666666667px #00a2ff, 88px -26.6666666667px #ff00fb, -68px -221.6666666667px #2200ff, -104px -18.6666666667px #00ffa6, 175px -299.6666666667px #ff6200, -5px -396.6666666667px #ffbf00, 5px -104.6666666667px #00ff22, 119px -364.6666666667px #37ff00, -42px -195.6666666667px #8400ff, -115px -99.6666666667px #0091ff, 35px -356.6666666667px #ff0033, 212px -9.6666666667px #ae00ff, -3px 28.3333333333px #00ffcc, -56px -365.6666666667px #ccff00, 129px -409.6666666667px #0095ff, 112px -242.6666666667px #aaff00, -89px 4.3333333333px #1aff00, 202px -41.6666666667px #a600ff, 144px -404.6666666667px #0400ff, -189px 17.3333333333px #00b7ff, 180px -35.6666666667px #00ffaa, -97px -174.6666666667px #00ffea, 243px -221.6666666667px #1aff00, -72px -26.6666666667px #ff0022, -119px -301.6666666667px #ff001a, -134px -179.6666666667px #ff6600, 48px -251.6666666667px #d500ff, 79px -144.6666666667px #ff006a, 19px -254.6666666667px #00ff6f, -132px -384.6666666667px #00ff22, -198px -9.6666666667px #ff4d00, -180px -31.6666666667px #0037ff, 164px 78.3333333333px #e1ff00, 189px -289.6666666667px #0026ff, -178px -92.6666666667px #7b00ff, 109px -233.6666666667px #ff0051, -219px -356.6666666667px #0015ff, -16px -53.6666666667px #6a00ff, 30px -194.6666666667px #c4ff00, -153px -190.6666666667px #0037ff, -198px -221.6666666667px #c400ff, -50px -234.6666666667px #ff00f2, -123px -297.6666666667px #95ff00;
  }
}
@-ms-keyframes bang {
  to {
    box-shadow: 199px -145.6666666667px #002bff, -181px -126.6666666667px #ff009d, -128px -137.6666666667px #ff4000, 16px -376.6666666667px #ea00ff, -180px 70.3333333333px #00ffaa, 95px 30.3333333333px #a200ff, 212px -407.6666666667px #ffe600, 18px 39.3333333333px #0009ff, -245px -260.6666666667px #00a2ff, 88px -26.6666666667px #ff00fb, -68px -221.6666666667px #2200ff, -104px -18.6666666667px #00ffa6, 175px -299.6666666667px #ff6200, -5px -396.6666666667px #ffbf00, 5px -104.6666666667px #00ff22, 119px -364.6666666667px #37ff00, -42px -195.6666666667px #8400ff, -115px -99.6666666667px #0091ff, 35px -356.6666666667px #ff0033, 212px -9.6666666667px #ae00ff, -3px 28.3333333333px #00ffcc, -56px -365.6666666667px #ccff00, 129px -409.6666666667px #0095ff, 112px -242.6666666667px #aaff00, -89px 4.3333333333px #1aff00, 202px -41.6666666667px #a600ff, 144px -404.6666666667px #0400ff, -189px 17.3333333333px #00b7ff, 180px -35.6666666667px #00ffaa, -97px -174.6666666667px #00ffea, 243px -221.6666666667px #1aff00, -72px -26.6666666667px #ff0022, -119px -301.6666666667px #ff001a, -134px -179.6666666667px #ff6600, 48px -251.6666666667px #d500ff, 79px -144.6666666667px #ff006a, 19px -254.6666666667px #00ff6f, -132px -384.6666666667px #00ff22, -198px -9.6666666667px #ff4d00, -180px -31.6666666667px #0037ff, 164px 78.3333333333px #e1ff00, 189px -289.6666666667px #0026ff, -178px -92.6666666667px #7b00ff, 109px -233.6666666667px #ff0051, -219px -356.6666666667px #0015ff, -16px -53.6666666667px #6a00ff, 30px -194.6666666667px #c4ff00, -153px -190.6666666667px #0037ff, -198px -221.6666666667px #c400ff, -50px -234.6666666667px #ff00f2, -123px -297.6666666667px #95ff00;
  }
}
@keyframes bang {
  to {
    box-shadow: 199px -145.6666666667px #002bff, -181px -126.6666666667px #ff009d, -128px -137.6666666667px #ff4000, 16px -376.6666666667px #ea00ff, -180px 70.3333333333px #00ffaa, 95px 30.3333333333px #a200ff, 212px -407.6666666667px #ffe600, 18px 39.3333333333px #0009ff, -245px -260.6666666667px #00a2ff, 88px -26.6666666667px #ff00fb, -68px -221.6666666667px #2200ff, -104px -18.6666666667px #00ffa6, 175px -299.6666666667px #ff6200, -5px -396.6666666667px #ffbf00, 5px -104.6666666667px #00ff22, 119px -364.6666666667px #37ff00, -42px -195.6666666667px #8400ff, -115px -99.6666666667px #0091ff, 35px -356.6666666667px #ff0033, 212px -9.6666666667px #ae00ff, -3px 28.3333333333px #00ffcc, -56px -365.6666666667px #ccff00, 129px -409.6666666667px #0095ff, 112px -242.6666666667px #aaff00, -89px 4.3333333333px #1aff00, 202px -41.6666666667px #a600ff, 144px -404.6666666667px #0400ff, -189px 17.3333333333px #00b7ff, 180px -35.6666666667px #00ffaa, -97px -174.6666666667px #00ffea, 243px -221.6666666667px #1aff00, -72px -26.6666666667px #ff0022, -119px -301.6666666667px #ff001a, -134px -179.6666666667px #ff6600, 48px -251.6666666667px #d500ff, 79px -144.6666666667px #ff006a, 19px -254.6666666667px #00ff6f, -132px -384.6666666667px #00ff22, -198px -9.6666666667px #ff4d00, -180px -31.6666666667px #0037ff, 164px 78.3333333333px #e1ff00, 189px -289.6666666667px #0026ff, -178px -92.6666666667px #7b00ff, 109px -233.6666666667px #ff0051, -219px -356.6666666667px #0015ff, -16px -53.6666666667px #6a00ff, 30px -194.6666666667px #c4ff00, -153px -190.6666666667px #0037ff, -198px -221.6666666667px #c400ff, -50px -234.6666666667px #ff00f2, -123px -297.6666666667px #95ff00;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  @apply mx-auto px-16 py-12 w-full h-full fixed top-0 left-0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */



</style>
